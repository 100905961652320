@import "./../header/Header.scss";

.sidebar {
  padding: 20px 0;
  min-height: 100%;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;

  .dumbnail-nav {
    display: flex;
    flex-direction: column;
    margin-top: 70px;

    label {
      font-size: 10px;
      margin-left: 10px;
      margin-bottom: 5px;
      margin-top: 10px;
    }

    .dumbnail-nav-link {
      display: flex;
      align-items: center;
      padding: 4px 8px;
      width: 100%;
      margin-bottom: 12px;
      margin-right: 30px;
      text-decoration: none;
      font-size: 15px;
      font-weight: 300;

      .icon {
        font-size: 1.5em;
        margin-right: 15px;
      }

      img {
        width: 22.5px;
      }
    }
  }

  .about-nav-link {
    display: flex;
    margin: auto 0;
    padding: 4px 8px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 300;
  }
}

@media screen and (max-width: 1050px) {
  .sidebar {
    display: none;
    .dumbnail-nav {
      flex-direction: row;
    }
  }
}

@media screen and (prefers-color-scheme: dark) {
  .sidebar {
    background-color: $dm-header-bg-color;
    color: $dm-dropdown-text-color;
    border-right: 0.5px solid rgba(255, 255, 255, 0.1);
    .dumbnail-nav {
      label {
        color: $dm-dropdown-text-color;
      }

      .dumbnail-nav-link {
        color: $dm-dropdown-text-color;

        &:hover {
          opacity: 0.95;
          background-color: rgba(128, 128, 128, 0.25);
        }
      }
    }
    .about-nav-link {
      color: $dm-dropdown-text-color;

      &:hover {
        opacity: 0.95;
        background-color: rgba(128, 128, 128, 0.25);
      }
    }
  }
}

@media screen and (prefers-color-scheme: light) {
  .sidebar {
    background-color: white;
    color: black;
    border-right: 0.5px solid rgba(128, 128, 128, 0.75);
    .dumbnail-nav {
      label {
        color: gray;
      }

      .dumbnail-nav-link {
        color: black;

        &:hover {
          opacity: 0.75;
          background-color: rgba(128, 128, 128, 0.25);
        }
      }
    }
    .about-nav-link {
      color: black;

      &:hover {
        opacity: 0.75;
        background-color: rgba(128, 128, 128, 0.25);
      }
    }
  }
}
