@import "./../../../index.scss";

.upload-container {
  .info-form {
    select {
      width: 100px;
    }
    .views-input,
    .posted-input {
      max-width: 150px;
      min-width: 91px;
      margin-right: 5px;
    }
  }
}

@media screen and (prefers-color-scheme: dark) {
  .info-form {
    input {
      color: $dm-text-color;
    }
    select {
      color: $dm-text-color;
    }
  }
}

@media screen and (prefers-color-scheme: light) {
  .info-form {
    color: black;
    select {
      color: black;
    }
  }
}
