$theme: rgb(253, 41, 123);
$time-message: rgb(154, 154, 154);
$them: #e6e6e6;
$you: #0eb6ee;

.tinder {
  .preview-ref {
    font-family: "Roboto", "Arial", sans-serif;
  }
  .message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;

    .match {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px 0 5px 40px;

      .custom-match-pic {
        width: 38px;
        height: 38px;
        margin-right: 5px;
        border-radius: 50%;
        overflow: hidden;
        background-color: rgba(253, 41, 123, 0.1);

        img {
          width: 100%;
        }
      }

      .match-pic {
        width: 38px;
        height: 38px;
        margin-right: 5px;
        border-radius: 50%;
        overflow: hidden;
      }

      .name {
        text-align: center;
        font-size: 10px;
        color: $time-message;
        font-weight: 400;
      }
    }
  }

  .message-body {
    padding: 10px;
    padding-top: 5px;
    .matched-on {
      font-size: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      color: $time-message;
      font-weight: 500;
      text-align: center;
    }

    .from-match {
      display: flex;
      justify-content: space-between;

      .custom-match-pic {
        width: 38px;
        height: 38px;
        margin-right: 5px;
        border-radius: 50%;
        overflow: hidden;
        background-color: rgba(253, 41, 123, 0.1);

        img {
          width: 100%;
        }
      }

      .last-picture {
        margin-left: 0 !important;
      }

      .tinder-msg {
        background-color: $them;
        padding: 5px 12px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 20px;
        margin-left: 43px;
        font-size: 14px;
        word-wrap: break-word;
        max-width: 220px;
        align-self: center;
      }

      .last {
        border-top-left-radius: 5px;
        margin-left: 0;
        margin-top: 5px;
      }

      svg {
        font-size: 20px;
        margin-right: 10px;
        padding-top: 5px;
        opacity: 0.1;
      }
    }

    .from-you {
      display: flex;
      justify-content: flex-end;
      .tinder-msg {
        margin-right: 10px;
        background-color: $you;
        color: white;
        padding: 5px 12px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 20px;
        margin-bottom: 5px;
        font-size: 14px;
        margin-top: auto;
        word-wrap: break-word;
        max-width: 220px;
      }

      .last {
        border-top-right-radius: 5px;
      }
    }
  }

  .match-pic-backdrop {
    background-color: rgba(253, 41, 123, 0.1);
    border: 1px dashed rgba(253, 41, 123, 0.5);
    border-radius: 50%;
    height: 38px;
    width: 38px;
  }

  .tinder-chevron-left {
    opacity: 0.3;
    font-size: 20px;
    margin-left: 10px;
  }

  .tinder-header-icons {
    width: 65px;
    margin-right: 10px;
    opacity: 0.75;
  }
  .tinder-download {
    text-align: center;
    align-items: center;
    border: none;
    border-top: 1px solid black;
    background-color: $theme;
    width: 100%;
    color: white;
    padding: 10px;

    &:hover {
      cursor: pointer;
      opacity: 0.75;
    }
  }
}

@media screen and (max-width: 445px) {
}
