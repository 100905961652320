$width: 314.5px;
$height: 176.9px;

// change this to .main upload-photo class
.upload-container {
  .upload-container-body {
    .upload-thumb {
      width: calc($width * 0.35);
      height: calc($height * 0.45);
      border: 1px dashed rgba(128, 128, 128, 0.75);
      background-color: rgba(128, 128, 128, 0.1);
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
        opacity: 0.75;
        transform: scale(101%);
      }

      .hidden-input {
        cursor: pointer;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }

      .upload-thumb-label {
        font-size: 10px;
      }

      svg {
        font-size: 10px;
      }
    }
  }

  .scroll-container {
    position: relative;
    overflow: hidden;
    margin-left: 0em;
    width: fit-content;

    .temps {
      margin-left: 1em !important;
    }
    .scroll {
      display: flex;
      overflow-x: scroll;
      max-width: 330px;
      padding-bottom: 15px;

      label {
        margin-bottom: 0.25em;
      }

      .temp {
        background-color: rgba(128, 128, 128, 0.1);
      }

      .temp,
      .blank-temp {
        min-width: calc(314.5px * 0.35);
        max-width: calc(314.5px * 0.35);
        height: calc(176.89px * 0.45);
        margin-right: 1em;

        &:hover {
          cursor: pointer;
          opacity: 0.75;
          transform: scale(101%);
        }

        .temp-label {
          text-align: center;
          color: white;
          background-color: red;
          border: 1px solid rgba(0, 0, 0, 0.6);
          font-size: 10px;
        }

        .blank-temp-label {
          text-align: center;
          color: black;
          background-color: rgba(255, 0, 0, 0.1);
          opacity: 0.75;
          border: 1px dashed rgba(255, 0, 0, 0.5);
          font-size: 10px;
        }

        img {
          width: 100%;
          height: 100%;
          border: 1px solid rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}

@media screen and (prefers-color-scheme: dark) {
  .upload-container {
    .upload-container-body {
      .upload-thumb {
        color: rgba(255, 255, 255, 0.35);
      }
    }
  }
}
