$dm-bg-color: rgb(28, 28, 28);
$dm-text-color: #bfbfbf;
$lm-bg-color: #f0f5fa;

html,
body {
  font-family: "Roboto", "Arial", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", "Arial", sans-serif;
}

html,
body,
#root,
.App {
  min-height: 100%;
  min-width: 100%;
}

@media screen and (prefers-color-scheme: dark) {
  html,
  body,
  #root,
  .App {
    background-color: $dm-bg-color;
  }
}

@media screen and (prefers-color-scheme: light) {
  html,
  body,
  #root,
  .App {
    background-color: $lm-bg-color;
  }
}
