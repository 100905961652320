$dm-bg-upload-container: rgb(28, 28, 33);
$dm-header: #bfbfbf;
$dm-icon-disabled: rgb(136, 136, 136);
// upload photo dimensions
$width: 314.5px;
$height: 176.9px;

.main {
  position: absolute;
  top: 70px;
  left: 160px;
  right: 0;
  bottom: 0;

  .main-components {
    display: flex;
    min-width: 330px;

    .upload-message-container {
      .upload-container {
        border-radius: 4px;
        margin: 20px 0 0 20px;
        padding: 20px;
        max-width: 413px;
        width: 413px;

        input,
        select,
        textarea {
          width: 100%;
          margin-bottom: 10px;
          padding: 5px;
          padding-left: 10px;
          font-size: 15px;
          outline: none;
          border: 1px dashed rgba(128, 128, 128, 0.75);
          background-color: rgba(128, 128, 128, 0.1);
          &:focus {
            background-color: rgba(128, 128, 128, 0.25);
          }
          resize: none;
        }
        .upload-container-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .icon-enabled {
            border-radius: 8px;
            align-items: center;
            padding: 8px;
            text-decoration: none;
            font-size: 1em;
            font-weight: 300;

            &:hover {
              cursor: pointer;
            }
          }

          .icon-disabled {
            border-radius: 8px;
            align-items: center;
            padding: 8px;
            text-decoration: none;
            font-size: 1em;
            font-weight: 300;
            opacity: 0.5;
          }
        }

        .upload-container-body {
          .upload-profile-pic {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            border: 1px dashed rgba(128, 128, 128, 0.75);
            background-color: rgba(128, 128, 128, 0.1);
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &:hover {
              cursor: pointer;
              opacity: 0.75;
              transform: scale(101%);
            }

            .hidden-input {
              cursor: pointer;
              opacity: 0;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              width: 100%;
              height: 100%;
            }

            .upload-profile-label {
              font-size: 10px;
            }

            svg {
              font-size: 10px;
            }
          }
        }
      }
    }

    .upload-containers-grid {
      display: grid;

      .upload-container {
        border-radius: 4px;
        margin: 20px 0 0 20px;
        padding: 20px;
        max-width: 413px;

        #clear-form {
          margin-top: 10px;
        }
        .info-form {
          input,
          select,
          textarea {
            width: 100%;
            margin-bottom: 10px;
            padding: 5px;
            padding-left: 10px;
            font-size: 15px;
            outline: none;
            border: 1px dashed rgba(128, 128, 128, 0.75);
            background-color: rgba(128, 128, 128, 0.1);
            &:focus {
              background-color: rgba(128, 128, 128, 0.25);
            }
          }
        }
        .upload-container-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .icon-enabled {
            border-radius: 8px;
            align-items: center;
            padding: 8px;
            text-decoration: none;
            font-size: 1em;
            font-weight: 300;

            &:hover {
              cursor: pointer;
            }
          }

          .icon-disabled {
            border-radius: 8px;
            align-items: center;
            padding: 8px;
            text-decoration: none;
            font-size: 1em;
            font-weight: 300;
            opacity: 0.5;
          }
        }

        .upload-container-body {
          margin-top: 10px;
          display: flex;

          .upload-photo {
            width: calc($width * 0.35);
            height: calc($height * 0.45);
            border: 1px dashed rgba(128, 128, 128, 0.75);
            background-color: rgba(128, 128, 128, 0.1);
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &:hover {
              cursor: pointer;
              opacity: 0.75;
              transform: scale(101%);
            }

            .hidden-input {
              cursor: pointer;
              opacity: 0;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              width: 100%;
              height: 100%;
            }

            .upload-photo-label {
              font-size: 10px;
            }

            svg {
              font-size: 10px;
            }
          }

          .upload-profile-pic {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            border: 1px dashed rgba(128, 128, 128, 0.75);
            background-color: rgba(128, 128, 128, 0.1);
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &:hover {
              cursor: pointer;
              opacity: 0.75;
              transform: scale(101%);
            }

            .hidden-input {
              cursor: pointer;
              opacity: 0;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              width: 100%;
              height: 100%;
            }

            .upload-profile-label {
              font-size: 10px;
            }

            svg {
              font-size: 10px;
            }
          }

          // for any scrollable popular list
          .scroll-container {
            position: relative;

            .scroll {
              display: flex;
              overflow-x: scroll;
              max-width: 330px;
              padding-bottom: 15px;
              margin-left: 0.75em;
              label {
                margin-bottom: 0.25em;
              }

              .popular {
                margin-right: 1.25em;
                width: 65px;
                &:hover {
                  cursor: pointer;
                  opacity: 0.75;
                  transform: scale(101%);
                }

                img {
                  border-radius: 50%;
                  width: 75px;
                  height: 100%;
                  border: 1px solid rgba(0, 0, 0, 0.6);
                }
              }
            }

            .before-overlay {
              content: "";
              position: absolute;
              z-index: 1;
              bottom: 0;
              left: 0;
              height: 100%;
              pointer-events: none;
              width: 15%;
            }

            .overlay {
              content: "";
              position: absolute;
              z-index: 1;
              bottom: 0;
              right: 0;
              height: 100%;
              pointer-events: none;
              width: 15%;
            }
          }
        }
      }

      .bottom-container {
        margin-top: 20px;
      }
    }

    .upload-containers-tabs {
      display: none;
      border-radius: 4px;
      padding: 20px;
      margin: 20px;
      margin-bottom: 0;
      margin-right: 0;
      height: fit-content;
      width: 413px;
      min-width: 330px;

      .nav-link.active {
        background-color: inherit;
      }

      .nav-link {
        &:hover {
          text-decoration: none;
        }
      }

      .nav-tabs {
        --bs-nav-tabs-border-width: 1px;
        --bs-nav-tabs-border-radius: 0;
      }

      .upload-container {
        #clear-form {
          margin-top: 10px;
        }
        .info-form {
          input,
          select,
          textarea {
            width: 100%;
            margin-bottom: 10px;
            padding: 5px;
            padding-left: 10px;
            font-size: 15px;
            outline: none;
            border: 1px dashed rgba(128, 128, 128, 0.75);
            background-color: rgba(128, 128, 128, 0.1);
            &:focus {
              background-color: rgba(128, 128, 128, 0.25);
            }
          }
        }
        .upload-container-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .icon-enabled {
            border-radius: 8px;
            align-items: center;
            padding: 8px;
            text-decoration: none;
            font-size: 1em;
            font-weight: 300;

            &:hover {
              cursor: pointer;
            }
          }

          .icon-disabled {
            border-radius: 8px;
            align-items: center;
            padding: 8px;
            text-decoration: none;
            font-size: 1em;
            font-weight: 300;
            opacity: 0.5;
          }
        }

        .upload-container-body {
          margin-top: 10px;
          display: flex;

          .upload-photo {
            width: calc($width * 0.35);
            height: calc($height * 0.45);
            border: 1px dashed rgba(128, 128, 128, 0.75);
            background-color: rgba(128, 128, 128, 0.1);
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &:hover {
              cursor: pointer;
              opacity: 0.75;
              transform: scale(101%);
            }

            .hidden-input {
              cursor: pointer;
              opacity: 0;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              width: 100%;
              height: 100%;
            }

            .upload-photo-label {
              font-size: 10px;
            }

            svg {
              font-size: 10px;
            }
          }

          .upload-profile-pic {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            border: 1px dashed rgba(128, 128, 128, 0.75);
            background-color: rgba(128, 128, 128, 0.1);
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &:hover {
              cursor: pointer;
              opacity: 0.75;
              transform: scale(101%);
            }

            .hidden-input {
              cursor: pointer;
              opacity: 0;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              width: 100%;
              height: 100%;
            }

            .upload-profile-label {
              font-size: 10px;
            }

            svg {
              font-size: 10px;
            }
          }

          // for any scrollable popular list
          .scroll-container {
            position: relative;
            overflow: hidden;
            margin-left: 0em;

            .scroll {
              display: flex;
              overflow-x: scroll;
              width: 100%;
              padding-bottom: 15px;
              margin-left: 0.75em;
              label {
                margin-bottom: 0.25em;
              }

              .popular {
                margin-right: 1.25em;
                width: 65px;
                &:hover {
                  cursor: pointer;
                  opacity: 0.75;
                  transform: scale(101%);
                }

                img {
                  border-radius: 50%;
                  width: 75px;
                  height: 100%;
                  border: 1px solid rgba(0, 0, 0, 0.6);
                }
              }
            }
            .before-overlay {
              content: "";
              position: absolute;
              z-index: 1;
              bottom: 0;
              left: 0;
              height: 100%;
              pointer-events: none;
              width: 15%;
            }

            .overlay {
              content: "";
              position: absolute;
              z-index: 1;
              bottom: 0;
              right: 0;
              height: 100%;
              pointer-events: none;
              width: 15%;
            }
          }
        }
      }
    }

    .preview-container {
      border-radius: 4px;
      padding: 20px;
      margin: 20px;
      height: fit-content;
      top: 90px;
      position: sticky;
      width: 413px;
      .preview-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;

        h4 {
          font-size: 20px;
          text-align: center;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  // re-centers content when sidebar is absent
  .main {
    left: 0;
  }
}

// maybe get rid of these !importants
@media screen and (max-width: 885px) {
  .main {
    .main-components {
      flex-direction: column;
      align-items: center;
    }
  }
  // replace containers grid with tabs
  .upload-containers-grid {
    display: none !important;
  }

  .upload-containers-tabs {
    margin: 20px !important;
    margin-bottom: 0 !important;
    display: block !important;
    width: fit-content;
  }
  .upload-message-container {
    margin-right: 20px;
  }
}

@media screen and (max-width: 450px) {
  .upload-containers-tabs {
    margin: 0 !important;
    margin-bottom: 20px !important;
    width: 100% !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.75) !important;
  }

  .upload-message-container {
    margin-right: 0 !important;
    width: 100%;
    .upload-container {
      width: fit-content !important;
      margin: 0 !important;
      margin-bottom: 20px !important;
      min-width: 100% !important;
      border: none !important;
      border-radius: 0 !important;
      border-bottom: 0.5px solid rgba(128, 128, 128, 0.75) !important;
    }
  }

  .preview-container {
    margin: 0 !important;
    width: 100% !important;
    border: none !important;
    border-top: 0.5px solid rgba(128, 128, 128, 0.75) !important;
    border-radius: 0 !important;
  }
}

@media screen and (prefers-color-scheme: dark) {
  .main {
    .main-components {
      .upload-containers-grid,
      .upload-message-container {
        .upload-container {
          background-color: $dm-bg-upload-container;
          border: 0.5px solid rgba(128, 128, 128, 0.75);

          .upload-container-header {
            h5 {
              color: $dm-header;
            }

            .icon-enabled {
              color: $dm-header;

              &:hover {
                cursor: pointer;
                opacity: 0.75;
                background-color: rgba(128, 128, 128, 0.25);
              }
            }

            .icon-disabled {
              color: $dm-icon-disabled;
            }
          }

          .upload-container-body {
            .upload-photo {
              .upload-photo-label,
              svg {
                color: rgba(255, 255, 255, 0.35);
              }
            }

            .upload-profile-pic {
              color: rgba(255, 255, 255, 0.35);
              .upload-profile-label {
                font-size: 10px;
              }

              svg {
                font-size: 10px;
              }
            }
            .scroll-container {
              position: relative;

              .before-overlay {
                background-image: linear-gradient(
                  to right,
                  $dm-bg-upload-container,
                  rgba(28, 28, 33, 0)
                );
                width: 15%;
              }

              .overlay {
                background-image: linear-gradient(
                  to left,
                  $dm-bg-upload-container,
                  rgba(28, 28, 33, 0)
                );
                width: 15%;
              }
            }
          }
        }
      }

      .upload-containers-tabs {
        background-color: $dm-bg-upload-container;
        border: 0.5px solid rgba(128, 128, 128, 0.75);
        .nav-link.active {
          background-color: inherit;
          border-color: rgba(255, 255, 255, 0.2);
          border-bottom-color: $dm-bg-upload-container;

          &:hover {
            border-color: rgba(255, 255, 255, 0.2);
            border-bottom-color: $dm-bg-upload-container;
          }
        }

        .nav-link {
          color: rgba(255, 255, 255, 0.3);

          &:hover {
            text-decoration: none;
            color: rgba(255, 255, 255, 0.2);
            border-color: rgba(255, 255, 255, 0.05);
          }
        }

        .nav-tabs {
          --bs-nav-tabs-border-width: 1px;
          --bs-nav-tabs-border-radius: 0;
          border-color: rgba(255, 255, 255, 0.2);
        }

        .upload-container {
          background-color: $dm-bg-upload-container;

          .upload-container-header {
            h5 {
              color: $dm-header;
            }

            .icon-enabled {
              color: $dm-header;

              &:hover {
                cursor: pointer;
                opacity: 0.75;
                background-color: rgba(128, 128, 128, 0.25);
              }
            }

            .icon-disabled {
              color: $dm-icon-disabled;
            }
          }

          .upload-container-body {
            .upload-photo {
              .upload-photo-label,
              svg {
                color: rgba(255, 255, 255, 0.35);
              }
            }
            .upload-profile-pic {
              color: rgba(255, 255, 255, 0.35);
              .upload-profile-label {
                font-size: 10px;
              }

              svg {
                font-size: 10px;
              }
            }
            .scroll-container {
              .before-overlay {
                background-image: linear-gradient(
                  to right,
                  $dm-bg-upload-container,
                  rgba(28, 28, 33, 0)
                );
                width: 15%;
              }

              .overlay {
                background-image: linear-gradient(
                  to left,
                  $dm-bg-upload-container,
                  rgba(28, 28, 33, 0)
                );
                width: 15%;
              }
            }
          }
        }
      }

      .preview-container {
        background-color: $dm-bg-upload-container;
        border: 0.5px solid rgba(128, 128, 128, 0.75);

        h5 {
          color: $dm-header;
        }

        .preview-header {
          h4 {
            color: $dm-icon-disabled;
            opacity: 0.5;
          }
        }
      }
    }
  }
}

@media screen and (prefers-color-scheme: light) {
  .main {
    .main-components {
      .upload-containers-grid,
      .upload-message-container {
        .upload-container {
          background-color: white;
          border: 0.5px solid rgba(128, 128, 128, 0.75);

          .upload-container-header {
            .icon-enabled {
              color: black;

              &:hover {
                cursor: pointer;
                opacity: 0.75;
                background-color: rgba(128, 128, 128, 0.25);
              }
            }

            .icon-disabled {
              color: black;
            }
          }

          .upload-container-body {
            .scroll-container {
              position: relative;

              .before-overlay {
                background-image: linear-gradient(
                  to left,
                  rgba(255, 255, 255, 0),
                  white
                );
                width: 15%;
              }

              .overlay {
                background-image: linear-gradient(
                  to right,
                  rgba(255, 255, 255, 0),
                  white
                );
                width: 15%;
              }
            }
          }
        }
      }

      .upload-containers-tabs {
        background-color: white;
        border: 0.5px solid rgba(128, 128, 128, 0.75);

        .nav-link.active {
          background-color: inherit;
          border-color: rgba(0, 0, 0, 0.2);
          border-bottom-color: rgba(255, 255, 255);

          &:hover {
            border-bottom-color: rgba(255, 255, 255);
            border-color: rgba(0, 0, 0, 0.2);
            border-bottom-color: rgba(255, 255, 255);
          }
        }

        .nav-link {
          color: rgba(0, 0, 0, 0.5);

          &:hover {
            text-decoration: none;
            color: rgba(0, 0, 0, 0.4);
            border-color: rgba(0, 0, 0, 0.1);
          }
        }

        .nav-tabs {
          --bs-nav-tabs-border-width: 1px;
          --bs-nav-tabs-border-radius: 0;
          border-color: rgba(0, 0, 0, 0.2);
        }

        .upload-container {
          .upload-container-header {
            .icon-enabled {
              color: black;

              &:hover {
                cursor: pointer;
                opacity: 0.75;
                background-color: rgba(128, 128, 128, 0.25);
              }
            }

            .icon-disabled {
              color: black;
            }
          }

          .upload-container-body {
            .upload-profile-pic {
              .upload-profile-label {
                font-size: 10px;
              }

              svg {
                font-size: 10px;
              }
            }
            .scroll-container {
              .before-overlay {
                background-image: linear-gradient(
                  to left,
                  rgba(255, 255, 255, 0),
                  white
                );
                width: 15%;
              }

              .overlay {
                background-image: linear-gradient(
                  to right,
                  rgba(255, 255, 255, 0),
                  white
                );
                width: 15%;
              }
            }
          }
        }
      }

      .preview-container {
        background-color: white;
        border: 0.5px solid rgba(128, 128, 128, 0.75);

        .preview-header {
          h4 {
            color: rgba(128, 128, 128, 0.5);
          }
        }
      }
    }
  }
}
