// light mode colors
$lm-message-text-you: rgb(248, 254, 255);
$lm-message-text-them: rgb(5, 5, 7);
$lm-message-header-background: rgb(241, 241, 241);
$lm-you: rgb(27, 137, 255);
$lm-them: rgb(233, 233, 235);
$lm-sub-message: rgb(139, 139, 139);
$lm-contact-image: rgb(140, 144, 155);
$lm-background: rgb(255, 255, 255);
// dark mode colors
$dm-message-text: rgb(251, 250, 252);
$dm-message-header-bg: #1f1f1f;
$dm-you: rgb(28, 138, 254);
$dm-them: rgb(49, 49, 50);
$dm-sub-message: rgb(154, 154, 154);
$dm-contact-image: rgb(160, 165, 178);
$dm-background: rgb(0, 0, 0);

.preview-container {
  .light,
  .dark {
    .message-header {
      .chevron-left {
        margin-left: 2px;
      }
      .contact-container {
        display: flex;
        justify-content: center;
        margin-top: 2.5px;
        margin-bottom: 3.25px;
      }
    }
  }

  .light {
    background-color: $lm-background;
    .message-header {
      min-height: 62.5px;
      align-items: center;
      border-bottom: 0.25px solid $lm-contact-image;
      background-color: $lm-message-header-background;

      .chevron-left {
        margin-top: 15px;
        margin-right: 5px;
        padding-left: 10px;
        font-size: 20px;
        color: $lm-you;
        opacity: 0.9;
      }

      .contact-image {
        display: flex;
        justify-content: center;
        margin-top: 5px;
        font-size: 18px;
        color: white;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $lm-contact-image;

        span {
          align-self: center;
          vertical-align: middle;
        }
      }

      .video-icon {
        margin-top: 12px;
        padding-right: 10px;
        width: 100%;
        max-height: 28px;
        opacity: 0.9;
      }

      .contact-name {
        font-size: 10px;
        margin-bottom: 3px;
        margin-right: 2px;
      }

      .chevron-right {
        color: $lm-contact-image;
        font-size: 10px;
        margin-top: 3px;
        opacity: 0.4;
      }
    }

    .message-body {
      padding: 7px 10px;
      max-height: 418px;
      overflow: hidden;
      max-height: 395px;

      .message-text {
        font-size: 15px;
        line-height: 18px;
        position: relative;
        word-wrap: break-word;

        .message-from-you {
          max-width: 220px;
          float: right;
          background-color: $lm-you;
          color: $lm-message-text-you;
          padding: 7px 14px;
          border-radius: 20px;
          margin-bottom: 1px;
          display: flex;
        }

        .message-from-you-tail,
        .message-from-you-blend {
          display: none;
        }

        .last-of-you {
          max-width: 220px;
          float: right;
          background-color: $lm-you;
          color: $lm-message-text-you;
          padding: 7px 14px;
          border-radius: 20px;
          margin-bottom: 7px;
        }

        .last-of-you-tail {
          position: absolute;
          bottom: 7px;
          right: -4px;
          justify-content: end;
          background-color: $lm-you;
          border-bottom-left-radius: 12px 10px;
          padding: 7px 0;
          height: 16px;
          width: 14px;
        }

        .last-of-you-blend {
          position: absolute;
          bottom: 7px;
          right: -10px;
          justify-content: end;
          border-bottom-left-radius: 7px;
          padding: 7px 0;
          height: 18px;
          width: 10px;
          background-color: $lm-background;
        }

        .message-from-them {
          max-width: 220px;
          float: left;
          background-color: $lm-them;
          color: $lm-message-text-them;
          padding: 7px 14px;
          border-radius: 25px;
          margin-bottom: 1px;
        }

        .message-from-them-tail,
        .message-from-them-blend {
          display: none;
        }

        .last-of-them {
          max-width: 220px;
          float: left;
          background-color: $lm-them;
          color: $lm-message-text-them;
          padding: 7px 14px;
          border-radius: 20px;
          margin-bottom: 7px;
        }

        .last-of-them-tail {
          position: absolute;
          bottom: 7px;
          left: -4px;
          justify-content: end;
          background-color: $lm-them;
          border-bottom-right-radius: 22px 10px;
          padding: 7px 0;
          height: 16px;
          width: 14px;
        }

        .last-of-them-blend {
          position: absolute;
          bottom: 7px;
          left: -10px;
          justify-content: end;
          border-bottom-right-radius: 8px;
          padding: 7px 0;
          height: 18px;
          width: 10px;
          background-color: $lm-background;
        }
      }

      .message-time {
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        color: $lm-sub-message;
        margin-bottom: 5px;

        .day {
          font-weight: 500;
        }
      }
      .time-not-first {
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        color: $lm-sub-message;
        margin-top: 6px;

        .day {
          font-weight: 500;
        }
      }
      .message-status {
        font-size: 10px;
        font-weight: 500;
        color: $lm-sub-message;
        float: right;
        margin-top: -3.5px;
      }
    }
  }

  .dark {
    background-color: $dm-background;
    .message-header {
      min-height: 62.5px;
      border-bottom: 0.25px solid $dm-message-header-bg;
      align-items: center;
      background-color: $dm-message-header-bg;

      .chevron-left {
        margin-top: 15px;
        margin-right: 5px;
        padding-left: 10px;
        font-size: 20px;
        color: $lm-you;
        opacity: 0.9;
      }

      .contact-image {
        display: flex;
        justify-content: center;
        margin-top: 5px;
        font-size: 18px;
        color: white;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $dm-contact-image;

        span {
          align-self: center;
          vertical-align: middle;
        }
      }

      .video-icon {
        margin-top: 12px;
        padding-right: 10px;
        width: 100%;
        max-height: 28px;
        opacity: 0.9;
      }

      .contact-name {
        font-size: 10px;
        color: white;
        margin-bottom: 3px;
        margin-right: 2px;
        // font-family: "SF-Pro";
      }
      .chevron-right {
        color: $dm-contact-image;
        font-size: 10px;
        margin-top: 3px;
        opacity: 0.4;
      }
    }

    .message-body {
      padding: 7px 10px;
      max-height: 418px;
      overflow: scroll;
      max-height: 395px;

      .message-text {
        font-size: 15px;
        line-height: 18px;
        position: relative;
        word-wrap: break-word;

        .message-from-you {
          max-width: 220px;
          float: right;
          background-color: $dm-you;
          color: $dm-message-text;
          padding: 7px 14px;
          border-radius: 20px;
          margin-bottom: 1px;
          display: flex;
        }

        .message-from-you-tail,
        .message-from-you-blend {
          display: none;
        }

        .last-of-you {
          max-width: 220px;
          float: right;
          background-color: $dm-you;
          color: $dm-message-text;
          padding: 7px 14px;
          border-radius: 20px;
          margin-bottom: 7px;
        }

        .last-of-you-tail {
          position: absolute;
          bottom: 7px;
          right: -4px;
          justify-content: end;
          background-color: $dm-you;
          border-bottom-left-radius: 12px 10px;
          padding: 7px 0;
          height: 16px;
          width: 14px;
        }

        .last-of-you-blend {
          position: absolute;
          bottom: 7px;
          right: -10px;
          justify-content: end;
          border-bottom-left-radius: 7px;
          padding: 7px 0;
          height: 18px;
          width: 10px;
          background-color: $dm-background;
        }

        .message-from-them {
          max-width: 220px;
          float: left;
          background-color: $dm-them;
          color: $dm-message-text;
          padding: 7px 14px;
          border-radius: 25px;
          margin-bottom: 1px;
        }

        .message-from-them-tail,
        .message-from-them-blend {
          display: none;
        }

        .last-of-them {
          max-width: 220px;
          float: left;
          background-color: $dm-them;
          color: $dm-message-text;
          padding: 7px 14px;
          border-radius: 20px;
          margin-bottom: 7px;
        }

        .last-of-them-tail {
          position: absolute;
          bottom: 7px;
          left: -4px;
          justify-content: end;
          background-color: $dm-them;
          border-bottom-right-radius: 22px 10px;
          padding: 7px 0;
          height: 16px;
          width: 14px;
        }

        .last-of-them-blend {
          position: absolute;
          bottom: 7px;
          left: -10px;
          justify-content: end;
          border-bottom-right-radius: 8px;
          padding: 7px 0;
          height: 18px;
          width: 10px;
          background-color: $dm-background;
        }
      }

      .message-time {
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        color: $dm-sub-message;
        margin-bottom: 5px;

        .day {
          font-weight: 500;
        }
      }
      .time-not-first {
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        color: $dm-sub-message;
        margin-top: 6px;

        .day {
          font-weight: 500;
        }
      }
      .message-status {
        font-size: 10px;
        font-weight: 500;
        color: $dm-sub-message;
        float: right;
        margin-top: -3.5px;
      }
    }
  }

  .message-download {
    text-align: center;
    align-items: center;
    border: 1px solid black;
    border-top: none;
    background-color: rgba(28, 138, 254);
    width: 100%;
    margin-right: 40px;
    color: white;
    padding: 10px;

    &:hover {
      cursor: pointer;
      opacity: 0.75;
    }
  }
}

.notes {
  color: #aaaaaa;
  font-size: 9px;
  margin-top: 10px;

  ul {
    padding-left: 5px;
    list-style: none;
  }
}

@media screen and (min-width: 451px) {
  .preview-container {
    max-width: 413px;
    width: 413px;
  }
}

@media screen and (max-width: 450px) {
  .preview-container {
    margin: 0;
    border-radius: 0;
    width: 100vw;
    min-width: none;
    padding-right: 20px;

    .message-header {
      min-height: fit-content !important;
      .contact-container {
        margin: 2vw 0 3vw;
        .contact-name {
          font-size: 2.25vw;
          margin: 0 0.5vw 0.5vw 0;
        }
        .chevron-right {
          font-size: 2vw;
        }
      }
      .video-icon {
        margin-top: 2vw !important;
        padding-right: 3vw !important;
        opacity: 0.9;
        width: 10vw !important;
      }
      .chevron-left {
        margin-top: 3vw !important;
        margin-right: 3vw !important;
        padding-left: 2vw !important;
        font-size: 5vw !important;
        color: rgb(27, 137, 255);
        opacity: 0.9;
      }
      .contact-image {
        height: 9.5vw !important;
        width: 9.5vw !important;
        span {
          font-size: 4.5vw;
        }
      }
    }

    .message-body {
      .message-time {
        font-size: 2.5vw !important;
      }

      .message-text {
        font-size: 5vw !important;

        .message-from-you,
        .last-of-you,
        .message-from-them,
        .last-of-them {
          padding: 1.75vw 3.5vw !important;
          font-size: 3.5vw !important;
        }
      }
    }

    .message-preview-container {
      min-width: 280px;
    }
  }
}

@media screen and (max-width: 390px) {
  .preview-container {
    margin: 0;
    border-radius: 0;
    width: 100vw;
    min-width: none;
    padding-right: 20px;

    .message-header {
      .contact-container {
        .contact-name {
          font-size: 2.25vw;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .preview-container {
    margin: 0;
    border-radius: 0;
    min-width: 280px;

    .small-screen-margin {
      min-width: 280px;
    }
    padding-right: 20px;

    .message-header {
      .contact-container {
        margin: 2px 0 3px;
        .contact-name {
          font-size: 7px;
          margin: 0 0.5px 0.5px 0;
        }
        .chevron-right {
          font-size: 7px;
        }
      }
      .video-icon {
        margin-top: 6.5px !important;
        padding-right: 10px;
        width: 32px !important;
        max-height: 28px;
        opacity: 0.9;
      }
      .chevron-left {
        margin-top: 10px !important;
        margin-right: 10px;
        padding-left: 2px;
        padding-right: 2px;
        font-size: 15px !important;
        color: rgb(27, 137, 255);
        opacity: 0.9;
      }
      .contact-image {
        height: 30px !important;
        width: 30px !important;

        span {
          font-size: 14px;
        }
      }
    }

    .message-body {
      .message-time {
        font-size: 8px !important;
      }

      .message-text {
        .message-from-you,
        .last-of-you,
        .message-from-them,
        .last-of-them {
          padding: 5.5px 11px !important;
          font-size: 11.5px !important;
        }
      }
    }

    .message-preview-container {
      min-width: 280px;
    }
  }
}
