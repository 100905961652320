// light mode colors
$light-gray: rgb(96, 106, 107);
$light-blue: rgb(71, 164, 227);
$light-black: rgb(45, 50, 52);
$light-handle-gray: rgb(121, 132, 140);
// dark mode colors
$dark-gray: #717374;
$dark-blue: rgb(33 166 255);
$dark-handle-gray: #717374;

// need to download correct font

.preview-container {
  .light,
  .dark {
    .tweet {
      font-weight: 300;
    }
  }

  .light {
    background-color: #ffffff;

    .twitter-options {
      color: $light-gray;
    }
    .twitter-name {
      color: $light-black;
    }

    .twitter-handle {
      color: $light-handle-gray;
    }

    .tweet-time,
    .tweet-date,
    .tweet-views {
      color: $light-gray;
    }

    .tweet-views {
      .views {
        color: #000000;
      }
    }

    .tweet-bullet {
      background-color: $light-gray;
    }

    .tweet-device {
      color: $light-blue;
    }
  }

  .dark {
    background-color: #000000;

    .twitter-options {
      color: $dark-gray;
    }

    .twitter-name {
      color: #ffffff;
    }

    .twitter-handle {
      color: $dark-handle-gray;
    }

    .tweet {
      color: #ffffff;
    }

    .tweet-time,
    .tweet-date,
    .tweet-views {
      color: $dark-gray;
    }

    .tweet-views {
      .views {
        color: #ffffff;
      }
    }

    .tweet-bullet {
      background-color: $dark-gray;
    }

    .tweet-device {
      color: $dark-blue;
    }
  }

  .twitter-preview-box {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    padding: 10px 20px 5px 10px;

    .account-info {
      margin-bottom: 4px;
      line-height: 15px;

      .twitter-options {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-top: 7px;
        opacity: 0.3;
        font-size: 12px;
      }

      .twitter-name {
        overflow-wrap: break-word;
      }

      .twitter-popular-prof {
        width: 48px;
        height: 48px;
        margin-right: 5px;
        border-radius: 50%;
        border: 0.25px solid rgba(0, 0, 0, 0.05);
      }

      .twitter-custom-prof {
        background-color: rgb(29, 155, 240);
        width: 48px;
        height: 48px;
        margin-right: 5px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
        }
      }

      .twitter-prof {
        width: 48px;
        height: 48px;
        margin-right: 5px;
        border-radius: 50%;
        border: 1px dashed rgba(29, 155, 240, 0.5);
        background-color: rgba(29, 155, 240, 0.1);
      }

      .indent {
        font-size: 13px;
        padding-top: 5px;
        height: 5px;
      }

      .twitter-name {
        margin-top: 2px;
        font-weight: 700;
      }

      .verified,
      .verified-dark {
        width: 15.02px;
        height: 15.02px;
        margin-left: 3px;
        margin-top: 1px;
      }
      .verified-gold {
        width: 15.02px;
        height: 16.02px;
        margin-left: 3px;
        margin-top: 0;
      }
    }

    .tweet {
      line-height: 28px;
      font-size: 23px;
      margin: 30px 0 20px 0;
    }

    .tweet-info {
      display: flex;
      .tweet-time,
      .tweet-date {
        font-size: 14px;
      }

      .tweet-bullet {
        display: flex;
        margin: auto 5px;
        width: 2px;
        height: 2px;
        border-radius: 100%;
      }

      .tweet-device,
      .tweet-views {
        font-size: 14px;
      }

      .tweet-views {
        .views {
          font-weight: 500;
        }
      }
    }
  }

  .twitter-download {
    text-align: center;
    align-items: center;
    border: none;
    border-top: 1px solid black;
    background-color: rgba(29, 155, 240);
    width: 100%;
    margin-right: 40px;
    color: white;
    padding: 10px;

    &:hover {
      cursor: pointer;
      opacity: 0.75;
    }
  }

  .notes {
    color: #aaaaaa;
    font-size: 9px;
    margin-top: 10px;

    ul {
      padding-left: 5px;
      list-style: none;
    }
  }
}

@media screen and (max-width: 450px) {
  .preview-container {
    .twitter-preview-box {
      padding: 3.16vw 6.32vw 1.58vw 3.16vw;

      .account-info {
        margin-bottom: 0.87vw;
        line-height: 3.25vw;

        .twitter-options {
          margin-top: 1.78vw;
          font-size: 3.07vw;
        }

        .twitter-popular-prof,
        .twitter-custom-prof,
        .twitter-prof {
          width: 12.03vw;
          height: 12.03vw;
          margin-right: 1.24vw;
        }

        .indent {
          font-size: 3.24vw;
          padding-top: 1.24vw;
          height: 1.24vw;
        }

        .twitter-name {
          margin-top: 0.51vw;
        }

        .twitter-handle {
          margin-top: 0.5vw;
        }

        .verified,
        .verified-dark {
          width: 3.93vw;
          height: 3.93vw;
          margin-left: 0.8vw;
          margin-top: 0.26vw;
        }

        .verified-gold {
          width: 3.93vw;
          height: 4.2vw;
          margin-left: 0.8vw;
        }
      }

      .tweet {
        line-height: 7.4vw;
        font-size: 6.07vw;
        margin: 7.87vw 0 5.25vw 0;
      }

      .tweet-info {
        .tweet-time,
        .tweet-date,
        .tweet-device,
        .tweet-views {
          font-size: 3.72vw;
        }

        .tweet-bullet {
          margin: auto 1.24vw;
          width: 0.51vw;
          height: 0.51vw;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .preview-container {
    .twitter-preview-box {
      padding: 10px 20px 6px 10px;

      .account-info {
        margin-bottom: 3.36px;
        line-height: 10.44px;

        .twitter-options {
          margin-top: 5.92px;
          font-size: 10.08px;
        }

        .twitter-popular-prof,
        .twitter-custom-prof,
        .twitter-prof {
          width: 38.64px;
          height: 38.64px;
          margin-right: 4.032px;
        }

        .indent {
          font-size: 10.416px;
          padding-top: 4.032px;
          height: 4.032px;
        }

        .twitter-name {
          margin-top: 1.68px;
        }

        .verified,
        .verified-dark {
          width: 12px;
          height: 12px;
          margin-left: 2.352px;
          margin-top: 0.96px;
        }
      }

      .tweet {
        line-height: 24.192px;
        font-size: 19.36px;
        margin: 25.472px 0 17.248px 0;
      }

      .tweet-info {
        .tweet-time,
        .tweet-date,
        .tweet-device,
        .tweet-views {
          font-size: 11.76px;
        }

        .tweet-bullet {
          margin: auto 4.032px;
          width: 1.68px;
          height: 1.68px;
        }
      }
    }
  }
}
