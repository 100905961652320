@import "./../../index.scss";

.home {
  margin: 20px;
  min-width: 320px;

  .example-link {
    &:hover {
      transform: scale(101%);
      opacity: 0.9;
    }
  }

  .examples-grid {
    margin-top: 20px;
    display: grid;

    .example {
      border: 1px solid black;
      border: 0.5px solid rgba(128, 128, 128, 0.75);
      border-radius: 4px;
      background-color: white;
      height: fit-content;
      margin: 5px;

      .example-title {
        text-align: center;
        font-size: 20px;
        padding: 5px 0;
      }

      .example-text {
        padding-right: 30px;
      }

      img {
        width: 100%;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .memeshot {
    text-decoration: none;
    color: inherit;
    &:hover {
      cursor: pointer;
      opacity: 0.75;
    }
  }

  h1 {
    font-size: 40px;
  }

  h3 {
    font-size: 25px;
  }

  .content-container {
    border-top: none;
    background-color: white;
    margin: 0 auto;
    width: fit-content;
    min-height: 100%;

    .content {
      padding: 1rem;
      padding-top: 0em;
    }
  }
}

@media screen and (max-width: 1050px) {
  // re-centers content when sidebar is absent
  .home {
    left: 0;
  }
}

@media screen and (max-width: 560px) {
  .home {
    .example-pics {
      img {
        width: inherit;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .home {
    .example-pics {
      img {
        width: inherit;
        padding-right: 30px;
      }
    }
  }
}

@media screen and (prefers-color-scheme: dark) {
  .home {
    color: $dm-text-color;

    .example {
      .example-title {
        color: black;
      }
    }
  }
}

@media screen and (prefers-color-scheme: light) {
  .home {
    color: black;
  }
}
