@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

.App {
  min-height: 100%;
  min-width: 100%;
  font-family: "IBM Plex Sans", sans-serif;

  h5 {
    font-weight: 400;
    font-size: 1.25rem;
    margin-bottom: 0;
  }

  .site {
    display: flex;
    min-height: 100%;
    // figure out why i need this
    overflow-x: hidden;
  }
}

@media screen and (max-width: 610px) {
  .site {
    flex-direction: column;
  }
}
