@import "./../../../index.scss";

$green: rgb(16, 163, 127);
$border: #2e2d37;

.upload-container {
  .edit-chat {
    .image-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      padding-top: 10px;

      input {
        width: 100%;
        border: 1px solid rgba(46, 45, 55, 0.15);
        outline: none;
        padding-left: 5px;
        border-radius: 0.125rem;
        &:focus {
          border: 1px solid rgba(46, 45, 55, 0.35);
        }
      }

      .color-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;

        .color {
          border: 1px solid $border;
          width: 30px;
          height: 30px;
          border-radius: 0.125rem;
          margin-left: 15px;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            color: white;
            display: block;
            font-size: 25px;
          }

          &:hover {
            cursor: pointer;
            opacity: 0.75;
            background-color: rgba(128, 128, 128, 0.25);
          }
        }
      }
    }
    .color-picker {
      display: flex;
      margin: 10px 0;
    }

    .chat-container {
      .message {
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
        border-top: 0.5px solid rgba(46, 45, 55, 0.75);

        .message-buttons {
          display: flex;
          align-items: center;

          .message-from {
            display: flex;

            .from,
            .from-active {
              padding: 6px;
              border-radius: 8px;
              margin-left: 5px;

              .pic {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                width: 20px;
                height: 20px;
                border-radius: 0.125rem;
              }
              img {
                width: 20px;
                height: 20px;
                border-radius: 0.125rem;
              }
            }

            .from-active {
              cursor: default;
              opacity: 1;
              background-color: inherit;
              box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
                rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
            }

            .from {
              &:hover {
                cursor: pointer;
                opacity: 0.75;
                background-color: rgba(128, 128, 128, 0.25);
              }
            }
          }

          .delete-message {
            color: black;
            border-radius: 8px;
            align-items: center;
            padding: 8px;
            text-decoration: none;
            font-size: 1em;
            font-weight: 300;
            margin-left: 15px;

            &:hover {
              cursor: pointer;
              opacity: 0.75;
              background-color: rgba(128, 128, 128, 0.25);
            }
          }
        }

        textarea {
          resize: none;
          width: 100%;
          border: 1px solid rgba(46, 45, 55, 0.15);
          outline: none;
          padding-left: 5px;
          margin-right: 15px;
          border-radius: 8px;

          &:focus {
            border: 1px solid rgba(46, 45, 55, 0.35);
          }
        }
      }
    }
  }

  .new-message-container {
    border-top: 0.5px solid rgba(46, 45, 55, 0.75);
    padding-top: 15px;
    .tinder-new-message {
      width: fit-content;
      padding: 4px 20px;
      border-radius: 8px;
      color: white;
      background-color: $green;

      &:hover {
        cursor: pointer;
        opacity: 0.75;
      }
    }

    .inactive {
      width: fit-content;
      padding: 4px 20px;
      border-radius: 8px;
      color: white;
      background-color: $green;
      opacity: 0.75;
      &:hover {
        cursor: default;
      }
    }
  }

  .notes {
    color: #aaaaaa;
    font-size: 9px;
    margin-top: 10px;

    ul {
      padding-left: 5px;
      list-style: none;
      margin-bottom: 0;
    }
  }
}

@media screen and (min-width: 881px) {
  .edit-chat-container {
    min-width: 413px;
    max-width: 413px;
  }
}

@media screen and (max-width: 881px) {
  .edit-chat-container {
    min-width: 440px;
    max-width: 440px;
  }
}

@media screen and (max-width: 450px) {
  .edit-chat-container {
    min-width: 100%;
    max-width: none;
    // min-width: 320px;
    margin: 0;
    border-radius: 0;
    margin-bottom: 20px;
  }
}

@media screen and (prefers-color-scheme: dark) {
  .upload-container {
    .edit-chat {
      color: $dm-text-color;
      input,
      textarea {
        color: $dm-text-color;
      }
      select {
        color: $dm-text-color;
      }
      .chat-container {
        .message {
          .message-buttons {
            .message-from {
              .from-active {
                cursor: default;
                opacity: 1;
                background-color: inherit;
                box-shadow: rgb(140, 140, 140, 0.1) 0px 1px 3px 0px,
                  rgba(187, 214, 241, 0.15) 0px 0px 0px 1px;
              }

              .from {
                &:hover {
                  cursor: pointer;
                  opacity: 0.75;
                  background-color: rgba(128, 128, 128, 0.25);
                }
              }
            }

            .delete-message {
              color: $dm-text-color;

              &:hover {
                cursor: pointer;
                opacity: 0.75;
                background-color: rgba(128, 128, 128, 0.25);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (prefers-color-scheme: light) {
  .upload-container {
    .edit-chat {
      color: black;
      input,
      textarea {
        color: black;
      }
      select {
        color: black;
      }
    }
  }
}
