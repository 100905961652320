$lm-bold-color: #262626;
$lm-light-gray: #878787;
$dm-bold-color: rgb(252, 252, 252);
$dm-light-gray: rgb(188, 189, 190);

.preview-container {
  img {
    height: 100%;
    width: 100%;
  }

  .light {
    background-color: white;
    .username,
    .likes {
      color: $lm-bold-color;
    }

    .comments,
    .date {
      color: $lm-light-gray;
    }
  }

  .dark {
    background-color: rgb(0, 0, 0);
    color: white;

    .username,
    .likes {
      color: $dm-bold-color;
    }

    .comments,
    .description,
    .date {
      color: $dm-light-gray;
    }
  }

  .instagram-preview-box {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    .post-header {
      align-items: center;
      display: flex;
      padding: 10px;
      position: relative;

      img,
      .account-backdrop {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 8px;
      }

      .account-backdrop {
        border: 1px dashed rgba(193, 53, 132, 0.5);
        background-color: rgba(193, 53, 132, 0.1);
      }

      .username {
        font-weight: 600;
        font-size: 14px;
        margin-right: auto;
      }

      .instagram-verified {
        margin-left: 4px;
        width: 12px;
        height: 12px;
        margin-bottom: 3px;
      }

      .post-header-options {
        width: 12px;
        height: 12px;
        opacity: 0.75;
        margin: 0;
      }
    }

    .post-body {
      // min-height: 212px;
      max-height: 471px;
      overflow: hidden;
      background-color: rgb(193, 53, 132, 0.1);
    }

    .upload-photo-backdrop {
      height: 212px;
      border-top: 1px dashed rgb(193, 53, 132, 0.5);
      border-bottom: 1px dashed rgb(193, 53, 132, 0.5);
      background-color: rgb(193, 53, 132, 0.1);
    }

    .post-footer {
      padding-bottom: 2px;
      padding-top: 2px;
      position: relative;

      .post-footer-data {
        .like-bar {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 0 10px;
          .bar {
            width: 110px;
          }
          .save {
            width: 30px;
          }
        }
      }

      .post-data {
        padding: 0 16px;
      }

      .likes {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 4px;
      }

      .username {
        font-weight: 600;
        font-size: 14px;
      }

      .description {
        max-width: 350px;
        font-size: 14px;
        line-height: 17px;
        max-height: 35px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        margin-bottom: 4px;
        font-weight: 400;
      }

      .comments {
        font-size: 14px;
        margin-bottom: 4px;
      }

      .date {
        font-size: 10px;
        display: flex;

        .month {
          margin-right: 5px;
        }
        .day {
          margin-right: 5px;
        }
      }
    }
  }

  .post-download {
    background-color: rgba(193, 53, 132, 1);
    text-align: center;
    align-items: center;
    border: none;
    width: 100%;
    color: white;
    padding: 10px;

    &:hover {
      cursor: pointer;
      opacity: 0.75;
    }
  }
}

// @media screen and (max-width: 885px) {
//   .preview-container {
//     .instagram-preview-box {
//       .post-header {
//         padding: 1.13vw;

//         img,
//         .account-backdrop {
//           width: 3.61vw;
//           height: 3.61vw;
//           margin-right: 0.9vw;
//         }

//         .username {
//           font-weight: 600;
//           font-size: 1.58vw;
//         }

//         .instagram-verified {
//           margin-left: 0.45vw;
//           width: 1.36vw;
//           height: 1.36vw;
//           margin-bottom: 0.34vw;
//         }

//         .post-header-options {
//           width: 1.36vw;
//           height: 1.36vw;
//         }
//       }

//       .post-footer {
//         padding-bottom: 0.23vw;
//         padding-top: 0.23vw;

//         .post-footer-data {
//           .like-bar {
//             padding: 0 1.13vw;
//             .bar {
//               width: 12.43vw;
//             }
//             .save {
//               width: 3.39vw;
//             }
//           }
//         }

//         .post-data {
//           padding: 0 1.8vw;
//         }

//         .likes {
//           font-size: 1.58vw;
//           margin-bottom: 0.45vw;
//         }

//         .username {
//           font-size: 1.58vw;
//         }

//         .description {
//           max-width: 39.55vw;
//           font-size: 1.58vw;
//           line-height: 1.91vw;
//           max-height: 3.95vw;
//           margin-bottom: 0.45vw;
//           font-weight: 400;
//         }

//         .comments {
//           font-size: 1.58vw;
//           margin-bottom: 0.45vw;
//         }

//         .date {
//           font-size: 1.13vw;

//           .month {
//             margin-right: 0.28vw;
//           }
//           .day {
//             margin-right: 0.28vw;
//           }
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 820px) {
//   .preview-container {
//     .instagram-preview-box {
//       .post-header {
//         padding: 10px;

//         img,
//         .account-backdrop {
//           width: 29px;
//           height: 29px;
//           margin-right: 7px;
//         }

//         .username {
//           font-weight: 600;
//           font-size: 14px;
//         }

//         .instagram-verified {
//           margin-left: 3px;
//           width: 11px;
//           height: 11px;
//           margin-bottom: 3px;
//         }

//         .post-header-options {
//           width: 11px;
//           height: 11px;
//         }
//       }

//       .post-footer {
//         padding-bottom: 2px;
//         padding-top: 2px;

//         .post-footer-data {
//           .like-bar {
//             padding: 0 9px;
//             .bar {
//               width: 100px;
//             }
//             .save {
//               width: 27px;
//             }
//           }
//         }

//         .post-data {
//           padding: 0 15px;
//         }

//         .likes {
//           font-size: 14px;
//           margin-bottom: 4px;
//         }

//         .username {
//           font-size: 14px;
//         }

//         .description {
//           max-width: 313px;
//           font-size: 14px;
//           line-height: 17px;
//           max-height: 39px;
//           margin-bottom: 4px;
//           font-weight: 400;
//         }

//         .comments {
//           font-size: 14px;
//           margin-bottom: 4px;
//         }

//         .date {
//           font-size: 10px;

//           .month {
//             margin-right: 2px;
//           }
//           .day {
//             margin-right: 2px;
//           }
//         }
//       }
//     }
//   }
// }

@media screen and (max-width: 450px) {
  .preview-container {
    .instagram-preview-box {
      .post-header {
        padding: 2.5vw;

        img,
        .account-backdrop {
          width: 10vw;
          height: 10vw;
          margin-right: 3.47vw;
        }

        .username {
          font-weight: 600;
          font-size: 3.5vw;
        }

        .instagram-verified {
          margin-left: 0.83vw;
          width: 2.78vw;
          height: 2.78vw;
          margin-bottom: 0.69vw;
        }

        .post-header-options {
          width: 2.78vw;
          height: 2.78vw;
        }
      }

      .post-footer {
        padding-bottom: 0.56vw;
        padding-top: 0.56vw;

        .post-footer-data {
          .like-bar {
            padding: 0 2vw;
            .bar {
              width: 25vw;
            }
            .save {
              width: 7vw;
            }
          }
        }

        .likes {
          font-size: 3.5vw;
          margin-bottom: 1.11vw;
        }

        .username {
          font-size: 3.5vw;
        }

        .description {
          max-width: 77.78vw;
          font-size: 3.5vw;
          line-height: 5.56vw;
          max-height: 19.78vw;
          margin-bottom: 1.11vw;
          font-weight: 400;
        }

        .comments {
          font-size: 3.5vw;
          margin-bottom: 1.11vw;
        }

        .date {
          font-size: 3.33vw;

          .month {
            margin-right: 0.83vw;
          }
          .day {
            margin-right: 0.83vw;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
}

@media screen and (max-width: 320px) {
  .preview-container {
    .instagram-preview-box {
      .post-header {
        padding: 8px;
        img,
        .account-backdrop {
          width: 32px;
          height: 32px;
          margin-right: 11.13px;
        }

        .username {
          font-weight: 600;
          font-size: 11.2px;
        }

        .instagram-verified {
          margin-left: 2.67px;
          width: 8.9px;
          height: 8.9px;
          margin-bottom: 2.2px;
        }

        .post-header-options {
          width: 8.9px;
          height: 8.9px;
        }
      }

      .post-footer {
        padding-bottom: 1.01px;
        padding-top: 1.01px;

        .post-footer-data {
          .like-bar {
            padding: 0 6.4px;
            .bar {
              width: 80px;
            }
            .save {
              width: 22.4px;
            }
          }
        }

        .likes {
          font-size: 11.2px;
          margin-bottom: 3.52px;
        }

        .username {
          font-size: 11.2px;
        }

        .description {
          max-width: 100%;
          font-size: 11.2px;
          line-height: 17.6px;
          max-height: 56.89px;
          margin-bottom: 3.52px;
          font-weight: 400;
        }

        .comments {
          font-size: 11.2px;
          margin-bottom: 3.52px;
        }

        .date {
          font-size: 10.67px;

          .month {
            margin-right: 2.67px;
          }
          .day {
            margin-right: 2.67px;
          }
        }
      }
    }
  }
}
